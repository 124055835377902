<template>
    <div class="section-wrap">
        <div v-show="loaded">
            <noscript>
                <img src="https://ad.doubleclick.net/ddm/activity/src=1524815;type=petin0;cat=petin005;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
            </noscript>
            <StepHeader :stepDetails=stepHeaderConfig :stepHeading="plansHeaderTitle" :contactInfo="this.getPartnerContactInfo()" :imageName="step2SideImgSource" ref="stepHeader"></StepHeader>
            <div class="step-body">
                <div class="form-inner">
                    <v-container>
                        <v-row justify="center">
                            <v-col cols="12">
                                <!-- <v-card flat v-if="pets.length > 1" class="v-card-form flat-bottom-radius pb-0 mb-4">
                                    <v-card-text>
                                        <v-row justify="center" v-if="pets.length > 1">
                                            <SummaryBar ref="summaryBar" @loadPlans="loadPlans" @switchPet="switchPet" @goToCheckout="goToCheckout" @handleShowIndividualPlanClick="handleShowIndividualPlanClick" @handleShowFamilyPlanClick="handleShowFamilyPlanClick" :pets="pets" :plans="selectedPolicies" :headers="planHeaders" :isFamilyPlan="isFamilyPlan" :key="summaryBarKey"></SummaryBar>
                                        </v-row>
                                    </v-card-text>
                                </v-card> -->
                                <v-card flat class="spacing-playground v-card-form ">
                                    <v-card-text>
                                        <v-row justify="center">
                                            <v-col cols="12" md="7" class="form-col-section">
                                               
                                                <PetsPanels
                                                    ref="petsPanels"
                                                    :announcePricingSummary="exposeTotalFamilyPricesToSR" 
                                                    :isCustomPlanActive="isCustomPlanActive"
                                                    :loadingNewPrices="loadingNewPrices" 
                                                    :loadingCompleteController="loadingCompleteController"
                                                    @renderSummaryBar="renderSummaryBar"
                                                    @renderPrincingComponent="renderPricingComponent" 
                                                    @setIsCustomPlanActive="setIsCustomPlanActive" 
                                                    @AddPetPetDialogFormFinished="AddPetPetDialogFormFinished"
                                                    @handleShowIndividualPlanClick="handleShowIndividualPlanClick"
                                                    @handleShowFamilyPlanClick="handleShowFamilyPlanClick"
                                                    @showAddPetDialog="showAddPetDialog"
                                                    :pricingKey="pricingKey"
                                                ></PetsPanels>
                                                

                                                <!-- Preventive Care Section -->
                                                <!-- <RoutineCare ref="routineCare" @renderSummaryBar="renderSummaryBar" :routinePlanConfig="routinePlanConfig" :riderOptions="riderOptions" :announcePriceCardChange="handleRoutineCareClick" @updateRoutineCare="updateRoutineCare($event)" /> -->
                                            </v-col>
                                            <v-col cols="12" md="5" class="side-col-section" role="tabpanel" id="side-col" v-show="$vuetify.breakpoint.mdAndUp">
                                                <v-card color="ml_lightgray" outlined elevation="0"  class="side-card">
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="10" offset="1" class="text-center">
                                                                <v-card-title>
                                                                    <v-img 
                                                                        alt="Snoopy" 
                                                                        class="shrink side-card-img"
                                                                        contain
                                                                        :src="step2SideImgSource"
                                                                        transition="scale-transition"
                                                                        max-height="212"
                                                                        width="auto"
                                                                        v-if="step2SideImgSource !== ''"
                                                                    />
                                                                </v-card-title>
                                                                <v-card-text :key="summaryBarKey">
                                                                    <div class="price-value xxl" aria-live="polite" v-if="isFamilyPlan">
                                                                        <span class="currency font-weight-bold">{{ calculateFamilyPlanTotal().charAt(0) }}</span>
                                                                        <span class="units font-weight-bold">{{ (calculateFamilyPlanTotal().slice(1)).split('.')[0] }}</span>
                                                                        <span class="cents font-weight-bold">.{{ (calculateFamilyPlanTotal().slice(1)).split('.')[1]}}
                                                                            <span class="payment-frequency d-flex flex-column align-start pl-2">Monthly <br /> Premium</span>
                                                                        </span>
                                                                    </div>
                                                                    <div class="price-value xxl" aria-live="polite" v-else>
                                                                        <span class="currency font-weight-bold">{{ getPriceSplit( calculateTotals() )[0] }}</span>
                                                                        <span class="units font-weight-bold">{{ getPriceSplit( calculateTotals() )[1]}}</span>
                                                                        <span class="cents font-weight-bold">{{ getPriceSplit( calculateTotals() )[2]}}
                                                                            <span class="payment-frequency d-flex flex-column align-start pl-2">Monthly <br /> Premium</span>
                                                                        </span>
                                                                    </div>
                                                                    <div class="mb-3" v-if="isFamilyPlan">
                                                                        <v-chip color="ml_teal lighten-2 font-weight-medium large" label> Family Plan</v-chip>
                                                                    </div>
                                                                    <!-- <span class="ml_blue--text d-flex flex-row justify-center align-center"> <v-icon class="ml_green--text mt-n1"> mdi-check </v-icon>{{ this.getDiscounstApplied() }}</span> -->
                                                                     <div class=" text-left d-flex flex-row justify-start align-start" v-for="discount in getSavedDiscountsData()" :key="discount.discountId">
                                                                         <v-icon class="ml_green--text mt-n1 mr-1"> mdi-check </v-icon> <span class="ml_gray--text text--darken-4">{{ discount.name }}</span>
                                                                    </div>
                                                                </v-card-text> 

                                                                <v-card-actions class="d-flex flex-column black--text">
                                                                    <!-- TODO: Add @click="goNext" -->
                                                                    <v-btn block x-large color="ml_purple" tabindex="0" @click="goToCheckout()">
                                                                        Next Step
                                                                        <v-icon small>mdi-play</v-icon>
                                                                    </v-btn>
                                                                    <p class="pt-3 pb-7 support-phone">Need help or looking for more plan options? Call: 
                                                                        <a aria-labelledby="partner-phone-contact" class="ml_darkblue--text pt-3 pb-7 support-phone" :href="'tel:'+this.getPartnerContactInfo()" v-on:click="trackAdobeEventPhone()">  {{this.getNumbertoAlphanumberic(this.getPartnerContactInfo())}}</a>
                                                                    </p>
                                                                    
                                                                </v-card-actions>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                            <v-col>
                                                <!-- Plans Coverage & Add Pet Btn -->
                                                <v-card  flat class="strip-card mt-10" width="100%">
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="12" class="d-flex flex-row justify-md-center justify-left align-start flex-wrap  py-5">
                                                                <v-btn block id="metCoverBtn" class="btn-lower-case my-3" :aria-expanded="showCoverageInline ? 'true' : 'false'" text color="ml_blue" @click="handleToggle">
                                                                    <div class="d-flex flex-row justify-space-between flex-grow-1">
                                                                        <span>
                                                                            <v-icon class="pb-1 mr-1" large>$shield</v-icon>
                                                                            <span class="body-1 font-weight-medium">
                                                                            {{ 
                                                                                dynamicContent && dynamicContent.plansCoverageBtnText
                                                                                    ? dynamicContent.plansCoverageBtnText 
                                                                                    : defaultDynamicContent.plansCoverageBtnText 
                                                                            }}
                                                                            </span>
                                                                        </span>
                                                                        <v-spacer></v-spacer>
                                                                        <v-sheet class="icon-sheet"  width="33" height="33">
                                                                            <v-icon large v-if="showCoverageInline">mdi-menu-up</v-icon>
                                                                            <v-icon large v-else>mdi-menu-down</v-icon>
                                                                        </v-sheet>
                                                                    </div>
                                                                       
                                                                </v-btn>  

                                                                <!-- <div v-if="Object.keys(pets).length < 3 && Object.keys(pets).length !== maxPetView ">
                                                                    <v-btn id="addPetBtn" class="btn-lower-case my-3" text color="ml_blue" elevation="0" aria-label="Add Additional Pet." @click.stop="showAddPetDialog(true)">
                                                                            <v-icon class="pb-1 mr-2" large>$plusCircle</v-icon>
                                                                            <span>Add another pet</span>                            
                                                                    </v-btn>
                                                                </div> -->
                                                               
                                                            </v-col>
                                                        </v-row>
                                                        <v-card-text  v-if="String(this.partnerId) === '251072'">
                                                            <v-row>
                                                                <v-col class="d-flex flex-row align-start">
                                                                    <v-icon class="mr-2 info--text" large>mdi-information-slab-circle</v-icon>
                                                                    <p class="body-1 ">
                                                                        <p>If you currently have a pet insurance policy with another carrier, to avoid pre-existing conditions, you will want to continue that current coverage through the start date of your MetLife Pet Insurance policy to avoid a gap in coverage.</p>
                                                                    </p>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                        <v-expand-transition>
                                                            <v-row v-show="showCoverageInline">
                                                                <v-col>
                                                                    <CoverageInline :isMetGen ="isMetGen"/>
                                                                </v-col>
                                                            </v-row>
                                                        </v-expand-transition>
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                           
                                            <div>
                                                <editCreatePetDialog 
                                                    @closeDialog="showAddPetDialog"
                                                    @hasFinished="AddPetPetDialogFormFinished"
                                                    :dialog="newPetDialog"
                                                    :isEdit="false"
                                                >
                                                </editCreatePetDialog>
                                            </div>

                                            <v-col cols="12" class="form-col-section">
                                                
                                                <!-- DISCOUNTS -->
                                                <div role="region" aria-label="Discounts Applied">
                                                    <v-container>
                                                        <v-row justify="center" v-if="Object.keys(discounts).length != 0">
                                                            <v-col cols="12">
                                                                <v-card elevation="0" width="auto">
                                                                    <v-card-title class="px-0" > <span id="Discount-Title" role="heading" aria-level="2">Discounts Applied </span> <a aria-label="Go to Footnote 2 on Discounts." id="anchorFootnote2"  ref="anchorFootnote2" @click="goToElementAndFocus('footnote-2', 'footnote2')"><sup>[2]</sup></a>  </v-card-title>
                                                                    <v-card-text class="pa-0">
                                                                        <v-row class="d-flex flex-row flex-wrap">
                                                                            <span class="d-sr-only" id="CheckboxState" aria-hidden="true">Checking or unChecking the check box will affect the pricing in the pricing list above</span>
                                                                            <v-col cols="12" md="4" v-for="discount in discounts" :key="discount.discountId">
                                                                                <v-card height="100%" flat >
                                                                                    <v-card-text class="d-flex flex-row justify-start pa-6">
                                                                                        <div class="d-flex flex-row align-start justify-space-between mt-0 mb-3">
                                                                                            <v-icon>$discountIcon</v-icon>
                                                                                            <div class="fake_checkbox_wrapper">
                                                                                                <input type="checkbox" class="transparent_checkbox" :id="'discount-chk-native-' + discount.discountId.toString()" :aria-labelledby="'Discount-Title Checkbox-title-'+discount.discountId" :aria-describedby="'Checkbox-description-'+discount.discountId+' CheckboxState'" :checked="getDiscountValue(discount.discountId) ?'checked':null" @change="handleInputClick(discount.discountId)"/>
                                                                                            
                                                                                                <v-checkbox :aria-describedby="'Checkbox-description-'+discount.discountId+' CheckboxState'" 
                                                                                                :ref="'discount-chk-' + discount.discountId.toString()" 
                                                                                                :id="'discount-chk-' + discount.discountId.toString()" 
                                                                                                :aria-labelledby="'Discount-Title Checkbox-title-'+discount.discountId" 
                                                                                                :input-value="getDiscountValue(discount.discountId)" 
                                                                                                v-on:change="updateDiscounts($event,discount.discountId)"
                                                                                                hide-details
                                                                                                class="mt-0 pt-0"
                                                                                                tabindex="-1"
                                                                                                aria-hidden="true"
                                                                                                ></v-checkbox>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="d-flex flex-column">
                                                                                            <div class="d-flex flex-row align-start">
                                                                                                    <label :for="'discount-chk-' + discount.discountId.toString()" class="discount-details">
                                                                                                        <h4 role="none" class="mb-2" :id="'Checkbox-title-'+discount.discountId">
                                                                                                            {{discount.name}}                                                                                                
                                                                                                        </h4>
                                                                                                    </label>
                                                                                            </div>
                                                                                            <div class="d-flex flex-row">
                                                                                                <span  :id="'Checkbox-description-'+discount.discountId">{{discount.description}} 
                                                                                                    <a v-if="discount.name === 'Employer/Member Group Discount'" aria-label="Go to Footnote 3 on Group Discounts." id="anchorFootnote3"  ref="anchorFootnote3" @click.stop="goToElementAndFocus('footnote-3', 'footnote3')"><sup>[3]</sup></a>                                                                                                
                                                                                                    <a v-if="discount.name === '10% Discount'" aria-label="Go to Footnote 5 on Federal Discounts." id="anchorFootnote5"  ref="anchorFootnote5" @click.stop="goToElementAndFocus('footnote-5', 'footnote5')"><sup>[5]</sup></a>
                                                                                                </span>
                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                    </v-card-text>
                                                                                </v-card>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </div>

                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </div>
            <v-footer class="v-footer" ref="controlZoomLevel" app color="white" elevation="4" role="none">
                <v-row class="d-flex justify-center align-center">
                    <v-col  md="auto" cols="12" class="d-flex justify-center align-center pb-0">
                        <v-row class="fill-height" no-gutters>
                            <v-col class="pb-0" v-show="$vuetify.breakpoint.smAndDown">
                                <v-card-text class="px-0 pb-0" >
                                    <div class="price-value sm justify-start" aria-live="polite">
                                        <span class="currency font-weight-bold">{{ getPriceSplit( calculateTotals() )[0] }}</span>
                                        <span class="units font-weight-bold">{{ getPriceSplit( calculateTotals() )[1] }}</span>
                                        <span class="cents font-weight-bold">{{ getPriceSplit( calculateTotals() )[2] }}
                                            <span class="payment-frequency d-flex flex-column align-start pl-2">Monthly <br /> Premium</span>
                                        </span>
                                    </div>
                                    <span class="ml_blue--text d-flex flex-row justify-center align-center" style="font-size: 10px; line-height: 10px;"> <v-icon class="ml_green--text mt-n1"> mdi-check </v-icon>{{ this.getDiscounstApplied() }}
                                    <v-menu v-model="showDiscountsApplied" top :offset-y="offsetTipY"  :close-on-content-click="closeTipOnClick">
                                        <template v-slot:activator="{on, attrs }">
                                            <v-btn x-small v-bind="attrs" icon v-on="on"> <v-icon>mdi-chevron-up</v-icon></v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title>Discount{{ getSavedDiscountsData().length > 1 ? 's':'' }} Applied:</v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text class="py-3"  v-for="discount in getSavedDiscountsData()" :key="discount.discountId">
                                                <v-icon class="ml_green--text mt-n1 mr-1"> mdi-check </v-icon> {{ discount.name }}
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </span>
                                </v-card-text>
                            </v-col>
                            <v-col cols="auto" class="px-2"  v-show="$vuetify.breakpoint.smAndDown">
                                <v-divider vertical></v-divider>
                            </v-col>
                            <v-col class="body-2 align-self-center text-center">
                                {{ 
                                    dynamicContent && dynamicContent.plansFooterInstructions 
                                        ? dynamicContent.plansFooterInstructions 
                                        : defaultDynamicContent.plansFooterInstructions 
                                }}
                            </v-col>
                        </v-row>
                        
                    </v-col>
                    <v-col cols="auto" >
                        <v-btn @click="goBack">Back</v-btn>
                    </v-col>
                    <v-col cols="auto"  v-if="Object.keys(pets).length < 3 && Object.keys(pets).length !== maxPetView ">
                        <v-btn aria-label="Add Additional Pet." @click.stop="showAddPetDialog(true)">Add a pet</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn color="ml_green" tabindex="0" @click="goToCheckout()">
                            Next Step <v-icon class="mt-n1">mdi-menu-right</v-icon>  {{nextButtonText}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-footer>
        </div>
        <DotLoader :isAbsolute="false" :loading="!loaded" :onLoadingComplete="loadingCompleteController"/>
    </div>
</template>
<script>
    import StepHeader from '@/components/challenger/shared/StepHeader.vue';
    import SummaryBar from '@/components/challenger/shared/SummaryBar.vue';
    // import CoverageDialog from '../../components/shared/CoverageDialog.vue';
    import CoverageInline from '../../components/shared/CoverageInline.vue';
    import PricingPetTitle from '../../components/challenger/PricingPetTitle.vue';
    import Pricing from '@/components/challenger/Pricing.vue';
    import Customize from '../../components/challenger/Customize.vue'; 
    import editCreatePetDialog from '../../components/challenger/shared/editCreatePetDialog.vue';
    import RoutineCare from '@/components/challenger/RoutineCare.vue';

    import PetsPanels from '../../components/challenger/step2/PetsPanels.vue'; 


    import DotLoader from '@/components/shared/DotLoader.vue';
    import pricingPage from '../Global/JS/pricingPage.js';

    export default {
        mixins: [pricingPage],
        components: { PricingPetTitle, Pricing, Customize, SummaryBar, DotLoader, StepHeader, editCreatePetDialog, CoverageInline, PetsPanels },
        data() {
            return {
                stepHeaderConfig: [],
                newPetDialog: false,
                showCoverageInline: false,
                showDiscountsApplied: false,
                closeTipOnClick: true,
                offsetTipY: true,

            }
        },
        watch: {

        },
        created() {
            this.stepHeaderConfig = {
                stepHeading: this.dynamicContent && this.dynamicContent.plansHeaderTitle ? this.dynamicContent.plansHeaderTitle : this.defaultDynamicContent.plansHeaderTitle,
                currentStep: 2,
                totalSteps: 4
            };

            this.setImageSource(this.defaultSidebarImage, this.dynamicSidebarImage, "2", "setStep2SideImgSource");
        },
        mounted() {
            // this.getPlans();
        },
        computed: {
            partnerId: function () {
                return this.$store.getters.partnerId;
            },
            maxPetView() {
                return this.$store.getters.maxPetView;
            },
            nextButtonText: function () {
                if (this.nextPetIndex != null) {
                    return this.nextPetIndex === this.currentPetIndex ? `PLAN FOR ${this.pets[this.nextPetIndex].name}` : `PLAN FOR ${this.pets[this.nextPetIndex].name}`;
                } else {
                    return "";
                }
            },
            availableDiscounts: function () {
                return this.$store.state.discounts.options;
            },
            savedDiscounts: function () {
                return this.$store.state.discounts.selection;
            },
            plansHeaderTitle() {
                return this.defaultDynamicContent && this.defaultDynamicContent.plansHeaderTitle
                    ? this.dynamicContent && this.dynamicContent.plansHeaderTitle 
                        ? this.dynamicContent.plansHeaderTitle 
                        : this.defaultDynamicContent.plansHeaderTitle
                    : '';
            },
            panelsExpanded: {
                get(){
                    return this.$store.getters.getPanelsExpanded;
                },
                set(value){
                    this.$store.commit('setPanelsExpanded',value);
                }
            },
            switchBackIndividual() {
                return this.$store.getters.getSwitchBackIndividual;
            },
        },
        updated(){
            
        },
        methods: {
            getMonthlyPremium(index) {
                let selectPlanIndex = this.selectedPolicies.findIndex(p => p.petArrayIndex === index);
                if (selectPlanIndex !== -1) {
                    return this.selectedPolicies[selectPlanIndex].planDetails?.monthlyPremium || 0.00;
                }
                return 0.00;
            },
            calculateTotals: function () {
                let total = [];

                if (this.selectedPolicies.length > 0) {
                    Object.entries(this.selectedPolicies).forEach(([key, val]) => {
                        total.push(parseFloat(val.planDetails.monthlyPremium))
                    });
                }

                return total.reduce(function (total, num) { return total + num }, 0);
            },
            calculateFamilyPlanTotal: function () {
                let result = 0;

                if (this.selectedPolicies.length > 0) {
                    result = this.selectedPolicies[0].planDetails.monthlyPremium;
                }

                return this.formatCurrencyValue(result);
            },
            formatCurrencyValue(value) {
                return new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(value);
            },
            handleToggle(){
                this.showCoverageInline=!this.showCoverageInline;

                if(this.showCoverageInline){
                    let ldo = {
                        eventName: 'linkClick',
                        attributes: {
                            application: 'MLPI',
                            form: 'Pet Insurance Quote',
                            linkName: 'See what MetLife Covers'
                        }
                    };
                    digitalData.eventTrack('linkClick', ldo);
                }

                this.$nextTick(()=>{
                    let btn = document.getElementById("metCoverBtn");
                    btn.focus();
                })

            },
            goBack() {
                if (this.currentPetIndex === 0) {
                    this.$store.commit("setCurrentPage","Pet Info");
                    this.redirectToView(this.pageName);
                    
                } else {
                    var newPetIndex = this.currentPetIndex - 1;
                    this.$store.commit("setCurrentPetIndex", newPetIndex);
                    this.$store.commit("setCurrentPet", this.pets[newPetIndex]);
                    this.$store.commit("setCurrentPlans", this.getFastQuoteResponse.fastQuotes.petFastQuotes[newPetIndex].fastQuotes);
                }
            },
            planChange() {
                this.$refs.customize.planChange();
            },
            deselectTab() {
                this.$refs.pricingCards.activeTab = null;
            },
            selectHighDeductibleTab() {
                this.$refs.pricingCards.handleChange(2);
            },
            showAddPetDialog(status) {
                if (!status) {
                    this.$store.commit("setCurrentPetIndex", 0);
                } else {
                    let ldo = {
                    eventName: 'linkClick',
                        attributes: {
                            application: 'MLPI',
                            form: 'Pet Insurance Quote',
                            linkName: 'Add another pet',
                            leadId: this.leadId,
                            stepName: 'Plan Selection'
                        }
                    };
                    digitalData.eventTrack('linkClick', ldo);
                }

                this.newPetDialog = status;
                this.$store.commit("clearCurrentPet");
            },
            async AddPetPetDialogFormFinished(status, petIndex) {
                if(status === 'delete') this.currentPetIndex = 0;

                await this.loadPlans();

                if(status === 'create') this.$refs.petsPanels.handlePetCreated(petIndex);

                if(status === 'edit' && this.switchBackIndividual) this.$refs.petsPanels.handlePetCreated(petIndex);
                if(status === 'edit' && !this.switchBackIndividual) this.$refs.petsPanels.handlePetEdited(petIndex);

                if(status === 'delete') this.$refs.petsPanels.handlePetCreated(0);
            },
            trackAdobeEventPhone() {
                let ldo = {
                    eventName: 'linkClick',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        linkName: this.getPartnerContactInfo(),
                        leadId: this.leadId,
                        stepName: 'Plan Selection'
                    }
                };
                digitalData.eventTrack('linkClick', ldo);
            }, 
            handleShowFamilyPlanClick() {
                this.$store.commit("setCurrentPetIndex", 0);
                this.$store.commit("setSelectedPolicies", []);

                this.showFamilyPlans();
                // setTimeout(() => {
                //     this.$refs.showIndividualPlanBtn.$el.focus();
                //     if (this.$refs.pricingCards.exposePricingCardChangesToSR()) this.$refs.pricingCards.exposePricingCardChangesToSR();
                // }, 500);
            },
            showFamilyPlans() {
                this.$store.commit("setCurrentPlans", this.familyPlans);

                this.showFamilyPlan = false;
                this.$store.commit("setViewingFamilyPlan", true);
                this.$store.commit("setIsFamilyPlan", true);

                this.renderSummaryBar();
                this.hideCustomizedPlan();

                this.$refs.petsPanels.handlePetCreated(0);
            },
            async handleShowIndividualPlanClick() {

            await this.$store.commit("setCurrentPetIndex", 0);
                this.$store.commit("updatePet", this.$store.state.pets[this.$store.state.currentPetIndex]);
                this.$store.commit("setSelectedPolicies", []);

                this.showIndividualPlans();
                // setTimeout(() => {
                //     this.$refs.showFamilyPlanBtn.$el.focus();
                //     if (this.$refs.pricingCards.exposePricingCardChangesToSR()) this.$refs.pricingCards.exposePricingCardChangesToSR();
                // }, 500);
            },
            showIndividualPlans() {           
                this.$store.commit("setCurrentPlans", this.getFastQuoteResponse.fastQuotes.petFastQuotes[this.currentPetIndex].fastQuotes);

                this.showFamilyPlan = true;
                this.$store.commit("setViewingFamilyPlan", false);
                this.$store.commit("setIsFamilyPlan", false);
                this.renderSummaryBar();
                this.hideCustomizedPlan();

                this.$refs.petsPanels.handlePetCreated(0);
            },
            getSavedDiscountsData(){
                if(Array.isArray(this.availableDiscounts)) {
                    return this.availableDiscounts?.filter( discount => {
                        return this.savedDiscounts.includes(discount.discountId);
                    })
                } else {
                    return [];
                }
            }, 
            formatCurrencyValue(value) {
                return new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(value);
            },
        }
    }
</script>

<style>
.nextBtnFocus:focus{
    border:6px solid #0092CE !important;
    outline: none !important;
}
.list-no-buttlets{
    list-style-type: none;
}
</style>
