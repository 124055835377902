<template>
    <v-container>
        <div role="region" aria-labelledby="Coversage-Title">
           
            <v-card-title class="px-0 ml_darkergray--text">
                <span id="Coversage-Title" role="heading" aria-level="2">What does Metlife Pet Insurance Cover</span>
            </v-card-title>
            <v-card-text class="pa-0">
                <v-row class="justify-center">
                    <v-col cols="12" :sm="!isMetGen ? 6 : 6" :md="!isMetGen ? 6 : 4">
                        <v-card elevation="0" color="ml_lightgray flex-grow-1 pa-2" style="height:100%;" class="mx-auto">
                            
                            <v-card-title class="d-flex flex-column align-start ml_darkergray--text" id="planVisitsVetTitle" role="heading" aria-level="3">
                                <v-icon class="mb-4 d-block">$coverageCheck</v-icon>
                                <span>
                                {{ 
                                    dynamicContent && dynamicContent.plansCoverageVisitVetTitle
                                        ? dynamicContent.plansCoverageVisitVetTitle 
                                        : defaultDynamicContent.plansCoverageVisitVetTitle 
                                }}
                                </span>
                            </v-card-title>
                            <v-card-text>
                                <v-row class="py-4" id="planVisitVetDetails" role="region" aria-labelledby="planVisitsVetTitle">
                                    <v-col class="py-0 ml_darkergray--text" cols="12">
                                        <span>
                                            <span class="combine-text" v-html="dynamicContent && dynamicContent.plansCoverageVisitVetBlockHtml ? dynamicContent.plansCoverageVisitVetBlockHtml : defaultDynamicContent.plansCoverageVisitVetBlockHtml + '' + this.htmlLinkNote4">
                                            </span>   
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" :sm="!isMetGen ? 6 : 6" :md="!isMetGen ? 6 : 4">
                        <v-card elevation="0" color="ml_lightgray flex-grow-1 ml_darkergray--text pa-2" style="height:100%;" class="mx-auto">
                            
                            <v-card-title class="d-flex flex-column align-start" id="planInclussionTitle" role="heading" aria-level="3">
                                <v-icon class="mb-4 d-block">$coverageCheck</v-icon>
                                {{ 
                                    dynamicContent && dynamicContent.plansCoverageIncludedTitle
                                        ? dynamicContent.plansCoverageIncludedTitle 
                                        : defaultDynamicContent.plansCoverageIncludedTitle 
                                }}
                            </v-card-title>
                            <v-card-text>
                                <v-row class="py-4" id="planInclussionDetails" role="region" aria-labelledby="planInclussionTitle">
                                    <v-col class="py-0" cols="12" sm="12">
                                        <ul class="list-coverage" v-if="dynamicContent && dynamicContent.plansCoverageIncludedList.length > 0">
                                            <li v-for="(item, index) in dynamicContent.plansCoverageIncludedList" :key="index">{{item}}</li>
                                        </ul>
                                        <ul class="list-coverage" v-else>
                                            <li v-for="(item, index) in defaultDynamicContent.plansCoverageIncludedList" :key="index">{{item}}</li>
                                        </ul>
                                    </v-col> 
                                </v-row>
                                <v-row v-if="enrollmentCode !== '99-99-00-0002' && isValidPartnerId">
                                    <v-col>
                                        <p>
                                            While MetLife Pet Insurance may not cover all pre-existing conditions, we do provide coverage for pre-existing conditions that were covered by a previous insurance provider for group benefits coverage.
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" :sm="!isMetGen ? 6 : 6" :md="!isMetGen ? 6 : 4" v-if="!isMetGen">
                        <v-card elevation="0" color="ml_lightgray flex-grow-1 pa-2" style="height:100%;" class="mx-auto" >
                            <v-card-title class="d-flex flex-column align-start ml_darkergray--text"  id="planInclussionAfterPeriodTitle" aria-label="After a 6-month wait Period, Your pet is also covered for:">
                                <v-icon  class="mb-4 d-block">$coverageCheck</v-icon>
                                After a 6-month wait Period
                            </v-card-title>
                            <v-card-subtitle>Your pet is also covered for:</v-card-subtitle>
                            <v-card-text>
                                <v-row class="py-4" id="planInclussionAfterPeriodDetails" role="region" aria-labelledby="planInclussionAfterPeriodTitle">
                                    <v-col class="py-0" cols="12" sm="12"  lg="6">
                                        <ul class="list-coverage">
                                            <li> Intervertebral Disc Disease (IVDD)</li>
                                            <li> Anterior Crucate Ligaments</li>
                                            <li> Medical Cruciate</li>
                                        </ul>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="12" lg="6">
                                        <ul class="list-coverage">
                                            <li aria-label="Posterior Cruciate Ligaments,"> Posterior Cruciate Ligaments</li>
                                            <li> Cranial Cruciate Ligaments</li>
                                        </ul>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" :sm="!isMetGen ? 6 : 6" :md="!isMetGen ? 6 : 4">
                        <v-card elevation="0" color="ml_lightgray flex-grow-1 pa-2" style="height:100%;" class="mx-auto" >
                            <v-card-title class="d-flex flex-column align-start ml_darkergray--text"  id="planNotIncludedTitle" role="heading" aria-level="3">
                                <v-icon  class="mb-4 d-block">$coverageTimes</v-icon>
                                {{ 
                                    dynamicContent && dynamicContent.plansCoverageNotIncludedTitle
                                        ? dynamicContent.plansCoverageNotIncludedTitle 
                                        : defaultDynamicContent.plansCoverageNotIncludedTitle 
                                }}
                            </v-card-title>
                            <v-card-text>
                                <v-row class="py-4" id="planNotIncludedDetails" role="region" aria-labelledby="planNotIncludedTitle">
                                    <v-col class="py-0" cols="12" v-if="partner.id">
                                        <ul class="list-coverage" v-if="dynamicContent && dynamicContent.plansCoverageNotIncludedList.length > 0">
                                            <li v-for="(item, index) in dynamicContent.plansCoverageNotIncludedList" :key="index">{{item}}</li>
                                        </ul>
                                        <ul class="list-coverage" v-else>
                                            <li v-for="(item, index) in defaultDynamicContent.plansCoverageNotIncludedList" :key="index">{{item}}</li>
                                        </ul>
                                    </v-col>
                                    <v-col class="py-0" cols="12" v-else>
                                        <ul class="list-coverage" v-if="dynamicContent && dynamicContent.nonGroupPlansCoverageNotIncludedList.length > 0">
                                            <li v-for="(item, index) in dynamicContent.nonGroupPlansCoverageNotIncludedList" :key="index">{{item}}</li>
                                        </ul>
                                        <ul class="list-coverage" v-else>
                                            <li v-for="(item, index) in defaultDynamicContent.nonGroupPlansCoverageNotIncludedList" :key="index">{{item}}</li>
                                        </ul>
                                    </v-col>
                                </v-row>
                                
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                
            </v-card-text>
        </div>
    </v-container>
</template>
<script>
    export default{
        name: 'CoverageInline', 
        props: {
            isMetGen: false,
        },
        data(){
            return{
                htmlLinkNote4: `<a  aria-label="Go to Footnote 4 on Reimbursement." id="anchorFootnote4"  ref="anchorFootnote4" @click="goToElementAndFocus('footnote-4', 'footnote4')"><sup>[4]</sup></a>`,
            }
        },
        computed: {
            defaultDynamicContent() {
                return this.$store.getters.getDefaultDynamicContent.DynamicJson;
            },
            dynamicContent() {
                return this.$store.getters.getDynamicContent.DynamicJson;
            }, 
            enrollmentCode() {
                return this.$store.getters.enrollmentCode;
            },
            partner() {
                return this.$store.getters.partner;
            },
            partnerId() {
                return this.$store.getters.partnerId;
            },
            isValidPartnerId(){
                return this.partnerId !== null && this.partnerId !== undefined;
            }
        }, 
        
    }
</script>
<style>
.combine-text{ 
    display: inline;
}
.combine-text > p, .combine-text > a{
    display: contents;
}
</style>